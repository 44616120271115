<template>
  <LandingPage />
</template>

<script>
import LandingPage from './components/LandingPage/LandingPage.vue'
export default {
  components: { LandingPage },
  name: 'App',
  mounted() {
    document.title = 'Laura Cares'
  }
}
</script>

<style src="./App.css">
</style>
