import { createApp } from 'vue';
import App from './App.vue'
import { 
    applyPolyfills,
    defineCustomElements
} from '@aws-amplify/ui-components/loader';
import PrimeVue from 'primevue/config';
import Button from 'primevue/button';

import 'primevue/resources/themes/saga-green/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";

applyPolyfills().then(() => {
    defineCustomElements(window);
});

const app = createApp(App);

app.use(PrimeVue);
app.component('PrimeButton', Button);

app.mount('#app');
