<template>
  <div class="grid grid-nogutter surface-section text-800 hero">
    <div class="col-12 xl:col-5 p-1 text-center xl:text-left flex pt-6">
      <section>
        <span class="block text-6xl font-bold mb-1">Laura Cares</span>
        <div class="text-6xl text-primary font-bold mb-3">Intro</div>
        <h4>Diagnosis</h4>
        <p>
          At only 59 years old, Laura recently moved into a Care Facility because she requires full-time assistance. A recent diagnosis of early onset dementia has devastated her family. Now in the later stages of Alzheimer's, Laura has suffered massive brain cell loss in her frontotemporal lobe. Several mini strokes have also impacted her short term memory and ability to follow complex conversations.
        </p>
        
        <h4>Laura Loves</h4>
        <p>
          Once a vibrant, quirky, fun-loving center of her family and community...she now spends her days looking at old photographs, reading short stories, and talking about her memories. Laura absolutely LOVES her family and friends.
        </p>

        <h4>Giving Back</h4>
        <p>
          If you have had a chance to know her and love her, please consider uploading messages, photos, or short video messages on this website. She will be able to see these while at her Care Facility. For more information about visiting or talking on the phone with Laura, you may reach out to her family.
        </p>
      </section>
    </div>
    <div class="col-12 xl:col-7 overflow-hidden hero-img-wrapper justify-content-center">
      <div class="hero-img-inner-wrapper">
        <img srcset="/img/Laura_Raccoon_16_9.jpg,
                      /img/Laura_Raccoon_16_9.jpg 800w"
              sizes="(max-width: 600px) 480px,
                    800px"
              src="/img/Laura_Raccoon_16_9.jpg 800w"
              alt="Laura and Raccoon"
              class="mt-3 xl:mt-0 block xl:h-full hero-right-poly m-auto">
      </div>
      <blockquote>
        "Make it a great day! Or, not. The choice is yours."
      </blockquote>
      <div class="text-700 text-center">
        <div class="text-900 font-bold text-5xl mb-3">
          Send a Message
        </div>
        <PrimeButton label="Coming Soon!" class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap p-disabled" />
      </div>
    </div>
  </div>

  <div class="grid grid-nogutter surface-section text-800 mt-6 mb-6 justify-content-center">
    <div class="mr-4">
      <div class="gfm-embed" data-url="https://www.gofundme.com/f/laura-cares/widget/medium/" ref="gfmEmbed"></div>
    </div>

    <div class="ml-4 mr-4">
      <iframe src="https://www.facebook.com/plugins/group.php?href=https%3A%2F%2Fwww.facebook.com%2Fgroups%2F381780980381179%2F&width=280&show_metadata=false&height=240&appId=344685451088246" width="280" height="240" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
    </div>
  </div>

  <div class="surface-section text-800 mt-6 mb-6 text-center xl:text-left">
    <section>
      <div class="text-6xl text-primary font-bold mb-3">
        Useful Links
      </div>
      <div class="text-800 mb-3">
        <ul class="unstyled">
          <li><PrimeButton label="Early Signs of Dementia" icon="pi pi-external-link" class="p-button-link" @click="externalLink('https://www.alz.org/alzheimers-dementia/what-is-alzheimers/younger-early-onset')" /></li>
          <li><PrimeButton label="Frontotemporal Dementia" icon="pi pi-external-link" class="p-button-link" @click="externalLink('https://www.alzheimers.org.uk/about-dementia/types-dementia/frontotemporal-dementia')" /></li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LandingPage',
  mounted() {
    let goFundMeScript = document.createElement('script')
    goFundMeScript.innerHTML =`
      !function(t, e) {
      try {
          function n(t) {
              var n = e.createElement("iframe");
              return n.setAttribute("class", "gfm-embed-iframe"),
              n.setAttribute("width", "100%"),
              n.setAttribute("height", "540"),
              n.setAttribute("frameborder", "0"),
              n.setAttribute("scrolling", "no"),
              n.setAttribute("src", t),
              n
          }
          t.addEventListener("message", function(t) {
              t.data && ((function(t) {
                  return [].slice.call(e.getElementsByClassName("gfm-embed-iframe")).filter(function(e) {
                      return e.contentWindow === t.source
                  })[0]
              }(t)).height = t.data.offsetHeight)
          }, !1),
          e.addEventListener("DOMContentLoaded", function() {
              for (var t = e.getElementsByClassName("gfm-embed"), r = 0; r < t.length; r++) {
                  var i = n(t[r].getAttribute("data-url"));
                  t[r].appendChild(i)
              }
          })
      } catch (t) {}
      }(window, document);
    `
    goFundMeScript.defer = true
    this.$refs.gfmEmbed.after(goFundMeScript)
  },
  methods: {
    externalLink(href) {
      window.open(href, '_blank');
    }
  }
}
</script>

<style src="./LandingPage.css"></style>
